<script>
import { bindUser, loadAdminBindLinkCodeInfo } from '@/api/data'

export default {
  name: 'BindDriver',
  data () {
    return {
      loading: true,
      binding: false,
      errMsg: null,
      authInfo: null
    }
  },
  created () {
    const search = window.location.search
    if (search.indexOf('?') !== -1) {
      const params = search.split('?')[1]
      const paramArr = params.split('&')
      const paramObj = {}
      paramArr.forEach(item => {
        const itemArr = item.split('=')
        paramObj[itemArr[0]] = itemArr[1]
      })
      if (!paramObj.code || paramObj.code.length != 20) {
        this.loading = false
        this.errMsg = this.$t('user.share.code.params.err')
      } else {
        loadAdminBindLinkCodeInfo({ code: paramObj.code }).then(res => {
          this.loading = false
          if (res.code === 0) {
             const info = JSON.parse(res.data)
             this.authInfo = {
               tip: this.$t('user.share.bind.tip').replace('{name}', info.name),
               user_id: info.user_id,
               access_token: info.session
            }
          } else {
            if (navigator.language.indexOf('ja') == 0) {
              this.errMsg = res.msg.ja
            } else {
              this.errMsg = res.msg.en
            }
          }
        })
      }
    } else {
      this.loading = false
      this.errMsg = this.$t('user.share.code.params.err')
    }
  },
  methods: {
    bindDriver () {
       this.binding = true
      bindUser({
        userid: this.authInfo.user_id,
        access_token: this.authInfo.access_token
      }).then(res => {
        this.binding = false
        this.$message.success(this.$t('user.action.bind.success'))
        setTimeout(() => {
          window.location.href = '/#/driver/list'
        }, 1000)
      })
    }
  }
}
</script>

<template>
  <div style="height: 100vh;overflow: hidden;">
    <a-row>
      <a-col :xl="{offset:6,span:12}">
        <div style="display: table;height: 100vh;width: 100%;">
          <div style="display: table-cell;vertical-align: middle;">
            <div style="text-align: center;" v-if="loading"><a-spin></a-spin><span style="margin-left: 4px;">{{ $t('user.share.code.checking') }}</span></div>
            <div v-else>
              <div v-if="errMsg" style="text-align: center;color:red;">{{ errMsg }}</div>
              <div v-if="authInfo">
                <a-result
                  status="success"
                  :title="authInfo.tip"
                >
                  <template #extra>
                    <a-button key="bind" type="primary" @click="bindDriver" :loading="binding">
                      {{ $t('user.share.bind') }}
                    </a-button>
                  </template>
                </a-result>
              </div>
            </div>
          </div>
        </div>

      </a-col>
    </a-row>
  </div>
</template>

<style scoped lang='less'>

</style>
